export const APP_ID: string = 'procoreonedrivesyncer-gwkxn';
export const API_KEY_ENCRYPTED: string = '8a0b4078eec69f56aa5d19770b744400d00fc87a30468b4b74659ad3270e05f979507056bd32ad3552a9df5ce6f89631a7f2284ffeb392722316badb0043c9662984e369b7a1543b59151483528ad6653e9a5d5f073e8821a8cb739228b7b55798b41c7b88d35d7385dde6d7948639c0507366f361721e19dd7657af2de63f975d9b035ec69348058f33b3ce4ad6059425fe5fa6d47cac1784883f271d6b805a';
export const ENDPOINT_KEY_ENCRYPTED: { [environment: string]: string} = {
    DEV: 'b36dde497e89deaffa2b805932fc27db926c8ee2cded984e3f15041ddf2fa539463426dce110ce52fd1307be5247bda368fc2525523a93873977c0140e3367315cd1085ed9ad9939b9d5d17f6a9edc4efd93b15f636b57b342c9b2d787224bfa2d88ba2de34f3519300b41f771ad5ed5eb46fd41d02f83e2c84266c131b8fbfc',
    TEST: 'b36dde497e89deaffa2b805932fc27db926c8ee2cded984e3f15041ddf2fa539463426dce110ce52fd1307be5247bda368fc2525523a93873977c0140e3367315cd1085ed9ad9939b9d5d17f6a9edc4efd93b15f636b57b342c9b2d787224bfa2d88ba2de34f3519300b41f771ad5ed5eb46fd41d02f83e2c84266c131b8fbfc',
    PROD: '6eac1f84cb86e1e1fa73f62a8ace44c53a90d0c0203545cb13cdbb029aa5ba17cc949f005eee434cae1cd0d12e55463d3ee0c2f547f108f02dd375a7cbe426d8e6ef29ef21d5a72ff9cfffc0f0b72d9a8d2a108a3a8e9e912b8eea13a0627528ddeca4be6612d927e0ce58d879e651b08caec3390570a0141ae273dcaad0908c'
}

export enum ENVIRONMENT {
    DEV = "DEV",
    TEST = "TEST",
    PROD = "PROD"
}
export enum DB_NAMES {
    DEV = "procoreSyncerDEV",
    TEST = "procoreSyncerTEST",
    PROD = "procoreSyncer"
}

export enum JOLEN_ENDPOINT {
    DEV = "https://jolenelectricapi.azure-api.net/devprocoreonedrivesync",
    TEST = "https://jolenelectricapi.azure-api.net/devprocoreonedrivesync",
    PROD = "https://jolenelectricapi.azure-api.net/azureprocore"
}