import Cryptr from 'cryptr';
import { ENDPOINT_KEY_ENCRYPTED, JOLEN_ENDPOINT, ENVIRONMENT } from '../credentials/creds';
import { IProcoreProject, IProcoreSPFolder, IFileJournalItem, IProjectDataSyncRequest, SyncType, FileSource, IEstimateFolderRequest } from '../models';

export enum ApiActions {
    AllProcoreProjects = 'procore/projects',
    ProcoreProjectInfo = 'procore/project',
    SPOProjectInfo = 'sharepoint/project',
    MongoDBProjectInfo = 'mongo/project',
    CreateProjectSync = 'project',
    EstimateFolderSyncAll = '/estimates/folder/syncall'
}

export default class JolenApi {
    private _encryptedKey: string = '';
    private _key: string | null = '';
    private _endpoint: JOLEN_ENDPOINT = JOLEN_ENDPOINT.DEV;

    constructor(targetEnvironment: ENVIRONMENT) {
        this._endpoint = JOLEN_ENDPOINT[targetEnvironment];
        this._encryptedKey = ENDPOINT_KEY_ENCRYPTED[targetEnvironment];
    }

    private _getFullEndpointUrl = (action: string): string => {
        return `${this._endpoint}/${action}?key=${this._key}`;
    };

    private _sendRequest = async (url: string, type: string, payload?: any): Promise<any> => {
        const requestSettings: RequestInit = {
            method: type.toUpperCase(),
            headers: {
                'cache-control': 'no-cache',
                'content-type': 'application/json',
                accept: 'application/json',
            },
        }

        if(payload) {
            requestSettings.body = JSON.stringify(payload);
        }

        const result = await fetch(url, requestSettings);

        const json = await result.json();

        return json;
    };

    public Login = (cryptr: Cryptr): boolean => {
        try {
            if (sessionStorage.decryptedApiKey) {
                this._key = sessionStorage.getItem('decryptedApiKey') || '';
            } else {
                // attempt to decrypt the string
                this._key = cryptr.decrypt(this._encryptedKey);
                sessionStorage.setItem('decryptedApiKey', this._key);
            }
        } catch (err) {
            console.error('Failed to decrypt jolen api key', err);

            return false;
        }

        return true;
    };

    public GetProcoreProjects = async (): Promise<IProcoreProject[]> => {
        const endpointUrl = this._getFullEndpointUrl(ApiActions.AllProcoreProjects);
        const projects = (await this._sendRequest(endpointUrl, 'GET')) as IProcoreProject[];

        return projects;
    };

    public GetProcoreProjectInfo = async (projectid: number): Promise<IProcoreProject> => {
        const endpointUrl = this._getFullEndpointUrl(`${ApiActions.ProcoreProjectInfo}/${projectid}`);
        const project = (await this._sendRequest(endpointUrl, 'GET')) as IProcoreProject;

        return project;
    };

    public GetSPOProjectInfo = async (projectname: string): Promise<IProcoreSPFolder> => {
        const endpointUrl = this._getFullEndpointUrl(`${ApiActions.SPOProjectInfo}/${decodeURIComponent(projectname)}`);
        const project = (await this._sendRequest(endpointUrl, 'GET')) as IProcoreSPFolder;

        return project;
    };

    public GetMongoProjectInfoProcoreId = async (procoreId: number): Promise<IFileJournalItem> => {
        const endpointUrl = this._getFullEndpointUrl(`${ApiActions.MongoDBProjectInfo}/fromprocore/${decodeURIComponent(procoreId.toString())}`);
        const project = (await this._sendRequest(endpointUrl, 'GET')) as IFileJournalItem;

        return project;
    };

    public GetMongoProjectInfoSPOId = async (spoId: number): Promise<IFileJournalItem> => {
        const endpointUrl = this._getFullEndpointUrl(`${ApiActions.MongoDBProjectInfo}/fromspo/${decodeURIComponent(spoId.toString())}`);
        const project = (await this._sendRequest(endpointUrl, 'GET')) as IFileJournalItem;

        return project;
    };

    public CreateSyncRequest = async (type: SyncType, source: FileSource, target: FileSource, project_id: number, ServerRelativeUrl: string): Promise<any> => {
        const payload: IProjectDataSyncRequest = {
            type,
            source,
            target,
            project_id,
            ServerRelativeUrl,
        };
        
        const endpointUrl = this._getFullEndpointUrl(`${ApiActions.CreateProjectSync}`);
        const payloadResult = await this._sendRequest(endpointUrl, 'POST', payload);

        return payloadResult;
    };

    public SyncAllEstimatesToAllUsers = async (): Promise<any> => {
        const payload: IEstimateFolderRequest = {
            type: SyncType.EstimateFoldersFull,
            estimateName: ''
        }

        const endpointUrl = this._getFullEndpointUrl(`${ApiActions.EstimateFolderSyncAll}`);
        const payloadResult = await this._sendRequest(endpointUrl, 'POST', payload);

        return payloadResult;
    }
}
