import React from 'react';
import { Spinner, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import ReactJson from 'react-json-view';
import md5 from 'md5';

import MongoDB from '../../services/mongo';
import { JobStatus, IWorkItem } from '../../models/IMongoDB';

// import Pagination from 'react-js-pagination';

export interface ITableTypeColumn {
    displayName: string;
    internalName: string[];
    renderFunction?: Function;
    fullWidth?: boolean;
    center?: boolean;
}

export interface IJobListState {
    jobs: IWorkItem[];
    refreshing: boolean;
    loading: boolean;
    activePage: number;
}

export interface IJobListProps {
    jobListType: JobStatus;
    mongo: MongoDB;
    secondsPerRefresh: number;
    itemsPerPage: number;
    searchQuery: string;
    totalResults: number;
}

export default class JobList extends React.Component<IJobListProps, IJobListState> {
    public state = {
        jobs: [],
        loading: false,
        refreshing: false,
        activePage: 1,
    };
    private refreshTimer: number = 0;
    private sorts: Record<JobStatus, number> = {
        'Not Started': 1,
        'In Progress': -1,
        Completed: -1,
        Error: -1,
    };

    private columnInfo: Record<JobStatus, ITableTypeColumn[]> = {
        'Not Started': [
            {
                displayName: 'Source',
                internalName: ['source'],
                renderFunction: this._renderSourceBadge,
                center: true,
            },
            {
                displayName: 'Logged',
                internalName: ['logged'],
                renderFunction: this._renderDate,
            },
            {
                displayName: 'Data',
                internalName: ['changedata', 'webhook'],
                renderFunction: this._renderObject,
                fullWidth: true,
            },
        ],
        'In Progress': [
            {
                displayName: 'Source',
                internalName: ['source'],
                renderFunction: this._renderSourceBadge,
                center: true,
            },
            {
                displayName: 'Started',
                internalName: ['started'],
                renderFunction: this._renderDate,
            },
            {
                displayName: 'Data',
                internalName: ['changedata', 'webhook'],
                renderFunction: this._renderObject,
                fullWidth: true,
            },
        ],
        Completed: [
            {
                displayName: 'Source',
                internalName: ['source'],
                renderFunction: this._renderSourceBadge,
                center: true,
            },
            {
                displayName: 'Logged',
                internalName: ['logged'],
                renderFunction: this._renderDate,
            },
            {
                displayName: 'Completed',
                internalName: ['completed'],
                renderFunction: this._renderDate,
            },
            {
                displayName: 'Log',
                internalName: ['outcome'],
                renderFunction: this._renderObject,
                fullWidth: true,
            },
            {
                displayName: 'Original Request',
                internalName: ['changedata', 'webhook'],
                renderFunction: this._renderObject,
                fullWidth: true,
            },
        ],
        Error: [
            {
                displayName: 'Source',
                internalName: ['source'],
                renderFunction: this._renderSourceBadge,
                center: true,
            },
            {
                displayName: 'Logged',
                internalName: ['logged'],
                renderFunction: this._renderDate,
            },
            {
                displayName: 'Completed',
                internalName: ['completed'],
                renderFunction: this._renderDate,
            },
            {
                displayName: 'Log',
                internalName: ['outcome'],
                renderFunction: this._renderObject,
                fullWidth: true,
            },
            {
                displayName: 'Original Request',
                internalName: ['changedata', 'webhook'],
                renderFunction: this._renderObject,
                fullWidth: true,
            },
        ],
    };

    componentDidMount(): void {
        this._getJobItems();
        this._setTimer();
    }

    componentDidUpdate(oldProps: IJobListProps): void {
        if (oldProps.jobListType !== this.props.jobListType || oldProps.searchQuery !== this.props.searchQuery) {
            this._killTimer();

            this.setState(
                {
                    loading: true,
                },
                () => {
                    this._getJobItems();
                    this._setTimer();
                }
            );
        }
    }

    private _killTimer = (): void => {
        window.clearInterval(this.refreshTimer);
    };

    private _setTimer = (): void => {
        // this.refreshTimer = window.setInterval(this._getJobItems, this.props.secondsPerRefresh * 1000);
    };

    private _getJobItems = async (): Promise<void> => {
        this.setState({
            refreshing: true,
        });
        const jobs = await this.props.mongo.GetJobs(
            this.props.jobListType,
            this.props.itemsPerPage,
            this.state.activePage,
            this.props.searchQuery,
            this.sorts[this.props.jobListType]
        );

        this.setState({
            jobs,
            refreshing: false,
            loading: false,
        });
    };

    private _renderDate(isoStr: string): JSX.Element {
        return (
            <Text variant="small" style={{ whiteSpace: 'nowrap' }}>
                {DateTime.fromISO(isoStr).toRelative()}
            </Text>
        );
    }

    private _renderSourceBadge(source: 'spo' | 'procore'): JSX.Element {
        const sourceBadgeClasses = {
            spo: 'badge-primary',
            procore: 'badge-warning',
        };

        return (
            <Text variant="small" className={`badge ${sourceBadgeClasses[source] || `badge-info`}`}>
                {(source || '').toUpperCase()}
            </Text>
        );
    }

    private _renderObject(data: any): JSX.Element {
        return <ReactJson collapsed={true} src={data} enableClipboard={false} displayDataTypes={false} displayObjectSize={false} collapseStringsAfterLength={128} />;
    }

    private async _restartJob(jobid: any): Promise<void> {
        const restarted = await this.props.mongo.RestartJob(jobid);

        if (restarted) {
            this._getJobItems();
        }
    }

    private async _deleteJob(jobid: any): Promise<void> {
        const goAhead = window.confirm('Are you sure you want to delete this item');
        if (goAhead) {
            const deleted = await this.props.mongo.DeleteJob(jobid);

            if (deleted) {
                this._getJobItems();
            }
        }
    }

    private _handlePageChange = async (newPage: number): Promise<void> => {
        this.setState(
            {
                activePage: newPage,
            },
            () => {
                this._getJobItems();
            }
        );
    };

    render(): JSX.Element {
        return (
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            {this.columnInfo[this.props.jobListType].map((info: ITableTypeColumn) => {
                                return (
                                    <th key={info.displayName} style={{ width: info.fullWidth ? '99999px' : 'auto' }}>
                                        {info.displayName}
                                    </th>
                                );
                            })}
                            <th></th>
                            <th>
                                <button className={`btn btn-success btn-sm`} onClick={() => this._getJobItems()}>
                                    Refresh
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.jobs.map((job: IWorkItem) => {
                            return (
                                <tr key={md5(JSON.stringify(job))}>
                                    {this.columnInfo[this.props.jobListType].map((info: ITableTypeColumn) => {
                                        if (info.renderFunction) {
                                            return (
                                                <td
                                                    key={md5(JSON.stringify(info))}
                                                    style={{ width: info.fullWidth ? '99999px' : 'auto', textAlign: info.center ? 'center' : 'left' }}>
                                                    {info.renderFunction((job as any)[info.internalName[0]] || (job as any)[info.internalName[1]])}
                                                </td>
                                            );
                                        } else {
                                            return <td key={md5(JSON.stringify(info))}>{(job as any)[info.internalName[0]] || (job as any)[info.internalName[1]]}</td>;
                                        }
                                    })}
                                    {this.props.jobListType !== JobStatus.NotStarted ? (
                                        <td>
                                            <button
                                                className={`btn btn-primary btn-sm`}
                                                onClick={() => {
                                                    this._restartJob(job._id);
                                                }}>
                                                Restart
                                            </button>
                                        </td>
                                    ) : null}
                                    <td>
                                        <button
                                            className={`btn btn-danger btn-sm`}
                                            onClick={() => {
                                                this._deleteJob(job._id);
                                            }}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {/* {this.props.totalResults > this.props.itemsPerPage ? (
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.props.itemsPerPage}
                        totalItemsCount={this.props.totalResults}
                        pageRangeDisplayed={5}
                        onChange={this._handlePageChange}
                        activeClass={`active`}
                        activeLinkClass={`active`}
                        itemClass={`page-item`}
                        itemClassFirst={``}
                        itemClassPrev={``}
                        itemClassNext={``}
                        itemClassLast={``}
                        disabledClass={`disabled`}
                        linkClass={`page-link`}
                        linkClassFirst={``}
                        linkClassPrev={``}
                        linkClassNext={``}
                        linkClassLast={``}
                    />
                ) : null} */}
                {this.state.refreshing ? <Spinner label="Refreshing data..." /> : null}
            </div>
        );
    }
}
