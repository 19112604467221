export enum SPChangeType {
    "NoChange" = 0,
    "Add",
    "Update",
    "DeleteObject", // 3
    "Rename", // 4
    "MoveAway",
    "MoveInto",
    "Restore",
    "RoleAdd", // 8
    "RoleDelete",
    "RoleUpdate",
    "AssignmentAdd",
    "AssignmentDelete",
    "MemberAdd",
    "MemberDelete",
    "SystemUpdate",
    "Navigation",
    "ScopeAdd",
    "ScopeDelete",
    "ListContentTypeAdd",
    "ListContentTypeDelete",
    "Dirty",
    "Activity"
}

export interface ISPUser {
    Title?: string;
    EMail?: string;
    Name?: string;
}

export interface ISPItem {
    AuthorId: number;
    Author?: ISPUser;
    CheckoutUserId: number;
    ComplianceAssetId: null;
    ContentTypeId: string;
    Created: string;
    EditorId: number;
    Editor?: ISPUser;
    FileSystemObjectType: number;
    GUID: string;
    Id: number;
    ID: number;
    Modified: string;
    ServerRedirectedEmbedUri: string;
    Title: string;
    File?: ISPFile;
    Folder?: ISPFolder;
    FileLeafRef?: string;
}

export interface ISPFile {
    CheckInComment: string;
    CheckOutType: number;
    ContentTag: string;
    CustomizedPageStatus: number;
    ETag: string;
    Exists: boolean;
    IrmEnabled: boolean;
    Length: string;
    Level: number;
    LinkingUri: string;
    MajorVersion: number;
    MinorVersion: number;
    Name: string;
    ServerRelativeUrl: string; //'/sites/ProcoreSyncTest/Shared Documents/Sandbox Test Project/newDoc3.docx'
    TimeCreated: string;
    TimeLastModified: string;
    Title: string;
    UIVersion: number;
    UIVersionLabel: string;
    UniqueId: string;
}

export interface ISPFolder {
    Exists: boolean;
    IsWOPIEnabled: boolean;
    ItemCount: number;
    Name: string;
    ProgID: number;
    ServerRelativeUrl: string; //'/sites/ProcoreSyncTest/Shared Documents/Sandbox Test Project/TEST FLDrr'
    TimeCreated: string;
    TimeLastModified: string;
    UniqueId: string;
    WelcomePage: string;
}

export interface IProcoreSPFile extends ISPItem {
    // ProcoreID: string;
    // ProcoreVersionID: string;
    // ProcoreProjectID: string;
    // Description1: string;
}

export interface IProcoreSPFolder extends ISPItem {
    // ProcoreID: string;
    // ProcoreVersionID: string;
    // ProcoreProjectID: string;
    // Description1: string;
}
export interface IProcoreSPProjectFolder extends ISPItem {
    // ProcoreProjectID: string;
    // ProcoreID: string;
}

export interface ISPChangeToken {
    StringValue: string;
}

export interface ISharePointChange {
    ChangeToken: ISPChangeToken,
    ChangeType: SPChangeType
    Editor: string;
    EditorEmailHint: string;
    ItemId: number;
    ListId: string;
    ServerRelativeUrl: string;
    SharedByUser: string;
    SharedWithUsers: string;
    SiteId: string;
    Time: string;
    UniqueId: string;
    WebId: string;
}
