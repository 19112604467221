import React from 'react';
import { PrimaryButton, Spinner } from '@fluentui/react';
import ReactJson from 'react-json-view';

import JolenApi from '../../services/jolenapi';
import MongoDB from '../../services/mongo';
import { IProcoreProject, IProcoreSPFolder, SyncType, FileSource } from '../../models';

export interface IProjectInfoProps {
    project: IProcoreProject;
    mongo: MongoDB;
    jolenApi: JolenApi;
}

export interface IProjectInfoState {
    loading: boolean;
    procoreProject: IProcoreProject;
    spoProject: IProcoreSPFolder;
    dbProject: any;
}

export default class ProjectInfo extends React.Component<IProjectInfoProps, IProjectInfoState> {
    public state: IProjectInfoState = {
        loading: false,
        procoreProject: {} as IProcoreProject,
        spoProject: {} as IProcoreSPFolder,
        dbProject: {},
    };

    public componentDidMount(): void {
        this._getExtendedProjectInfo();
    }

    public componentDidUpdate(oldProps: IProjectInfoProps, oldState: IProjectInfoState) {
        if (oldProps.project.id !== this.props.project.id) {
            this._getExtendedProjectInfo();
        }
    }

    private async _getExtendedProjectInfo(): Promise<void> {
        this.setState({
            loading: true,
        });

        let procoreProject = await this.props.jolenApi.GetProcoreProjectInfo(this.props.project.id);
        let spoProject = await this.props.jolenApi.GetSPOProjectInfo(this.props.project.name);
        let dbProject = await this.props.jolenApi.GetMongoProjectInfoProcoreId(this.props.project.id);

        if(!dbProject.ProcoreID) {
            dbProject = await this.props.jolenApi.GetMongoProjectInfoSPOId(spoProject.Id);
        }

        this.setState({
            procoreProject,
            spoProject,
            dbProject,
            loading: false,
        });
    }

    public render(): JSX.Element {
        const { procoreProject, spoProject, dbProject } = this.state;

        return (
            <div>
                {this.state.loading ? (
                    <Spinner />
                ) : (
                    <div>
                        <div className="row">
                            <div className="col"><h3>{procoreProject.name}</h3></div>
                        </div>
                        <div className="row">
                            {dbProject.message ? (
                                <PrimaryButton onClick={() => {
                                    this.props.jolenApi.CreateSyncRequest(SyncType.ProjectTopLevel, FileSource.SPO, FileSource.Procore, procoreProject.id, spoProject.Folder?.ServerRelativeUrl as string);
                                }} text={`Ensure Project`} />
                            ) : null}
                        </div>
                        <div className="row">
                            <div className="col col-xs-12 col-sm-4">
                                <h4>Procore Data</h4>
                                <ReactJson
                                    collapsed={true}
                                    src={procoreProject}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    collapseStringsAfterLength={128}
                                />
                            </div>
                            <div className="col col-xs-12 col-sm-4">
                                <h4>SPO Data</h4>
                                <ReactJson
                                    collapsed={true}
                                    src={spoProject}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    collapseStringsAfterLength={128}
                                />
                            </div>
                            <div className="col col-xs-12 col-sm-4">
                                <h4>Mongo Data</h4>
                                <ReactJson
                                    collapsed={true}
                                    src={dbProject}
                                    enableClipboard={false}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                    collapseStringsAfterLength={128}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
