import { IProcoreWebhook } from './IProcore';
import { ISharePointChange } from './ISharepoint';

export type TItemType = 'File' | 'Folder';

export enum JobStatus {
    NotStarted = "Not Started",
    InProgress = "In Progress",
    Completed = "Completed",
    Error = "Error"
}

export enum SyncType {
    File = "File",
    ProjectTopLevel = "ProjectTopLevel",
    ProjectFull = "ProjectFull",
    EstimateFoldersFull = "EstimateFoldersFull",
    EstimateFoldersUser = "EstimateFoldersUser"
}

export enum JobResult {
    NotStarted = 'Not Started',
    Error = 'Error',
    InProgress = 'In Progress',
    Completed = 'Completed',
}

export enum FileSource {
    Procore = "procore",
    SPO = "spo",
    Outlook = "outlook"
}

export interface IProjectDataSyncRequest {
    type: SyncType,
    source: FileSource;
    target: FileSource;
    project_id: number;
    ServerRelativeUrl: string;
}

export interface IEstimateFolderRequest {
    type: SyncType,
    estimateName: string
}

export interface IWorkItem {
    _id?: any;
    type: SyncType,
    source: FileSource,
    status: JobResult,
    outcome: string[],
    logged: string,
    started: string,
    completed: string,
    webhook?: IProcoreWebhook,
    changedata?: ISharePointChange,
    projectsync?: IProjectDataSyncRequest,
    estimatefoldersyncrequest?: IEstimateFolderRequest
}


export interface IFileJournalItem {
    _id?: any;
    Type: TItemType;
    SPOGuid: string;
    SPOListId: number;
    SPOServerRelativeUrl: string;
    ProcoreID: number;
    ProcoreProjectID: number;
    ProcoreParentID: number;
    ProcoreVersionID: number;
    filename: string;
    description?: string;
}