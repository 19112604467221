import React from 'react';

export interface IEstimatesListState {}

export interface IEstimatesListProps {
    pushAllEstimatesFunc: () => void;
}

export default class EstimatesList extends React.Component<IEstimatesListProps, IEstimatesListState> {
    public state: IEstimatesListState = {};

    public render(): JSX.Element {
        return (
            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <button
                    type="button"
                    className={`nav-link btn btn-link text-left`}
                    onClick={(ev) => {
                        ev.preventDefault();
                        this.props.pushAllEstimatesFunc();
                    }}>
                    Push estimates to all users in the automation group
                </button>
            </div>
        );
    }
}
