import * as Realm from 'realm-web';
import Cryptr from 'cryptr';

import { IWorkItem, JobStatus } from '../models/IMongoDB';
import { APP_ID, API_KEY_ENCRYPTED, DB_NAMES } from '../credentials/creds';

const ATLAS_SERVICE: string = 'mongodb-atlas';

export default class MongoDB {
    private app: Realm.App = new Realm.App({ id: APP_ID });
    private user: Realm.User = {} as Realm.User;
    private mongo = this.app.services.mongodb(ATLAS_SERVICE);
    private collection: any = null;

    constructor(DB_NAME: DB_NAMES) {
        this.collection = this.mongo.db(DB_NAME).collection('RequestLogs');
    }

    public Login = async (cryptr: Cryptr): Promise<boolean> => {
        let decryptedString = '';

        try {            
            if(sessionStorage.decryptedKey) {
                decryptedString = sessionStorage.getItem('decryptedKey') || '';
            } else {
                // attempt to decrypt the string
                decryptedString = cryptr.decrypt(API_KEY_ENCRYPTED);
                sessionStorage.setItem('decryptedKey', decryptedString);
            }

            // Authenticate the user
            const credentials = Realm.Credentials.apiKey(decryptedString);
            this.user = await this.app.logIn(credentials);

            return true;
        } catch (err) {
            console.error('Failed to log in', err);

            return false;
        }
    };

    public GetJobs = async (status: JobStatus, limit: number, pageNumber: number, query?: string, sort?: number): Promise<IWorkItem[]> => {
        const regExp = new RegExp(`${query}`, 'i');

        const findManyQueryFilter: any = { status };

        if(query) {
            findManyQueryFilter["outcome"] = { $regex: regExp };
        }
        const results: IWorkItem[] = await this.collection.find(findManyQueryFilter, { sort: { _id: sort || -1 }, limit });

        return results;
    };

    public CountJobs = async (status: JobStatus, query?: string): Promise<number> => {
        const regExp = new RegExp(`${query}`, 'i');
        const findManyQueryFilter: any = { status };

        if(query) {
            findManyQueryFilter["outcome"] = { $regex: regExp };
        }
        const results: number = await this.collection.count(findManyQueryFilter);

        return results;
    };

    public RestartJob = async (id: any): Promise<boolean> => {
        try {
            await this.collection.findOneAndUpdate({ _id: id }, { $set: { status: JobStatus.NotStarted } });
            return true;
        } catch(er) {
            console.log(er);
            return false;
        }
    };

    public DeleteJob = async (id: any): Promise<boolean> => {
        try {
            await this.collection.findOneAndDelete({ _id: id });
            return true;
        } catch(er) {
            console.log(er);
            return false;
        }
    };
}
