import React from 'react';
import { IProcoreProject } from '../../models/IProcore';

export interface IProjectListState {}

export interface IProjectListProps {
    projects: IProcoreProject[];
    currentProject: IProcoreProject;
    selectProject: (project: IProcoreProject) => void;
}

export default class ProjectList extends React.Component<IProjectListProps, IProjectListState> {
    public state: IProjectListState = {};

    public render(): JSX.Element {
        return (
            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                {this.props.projects.map((project, idx) => {
                    return (
                        <button
                            key={`projectButton-${idx}`}
                            type="button"
                            className={`nav-link ${this.props.currentProject.id === project.id ? 'active' : ''} btn btn-link text-left`}
                            onClick={(ev) => {
                                ev.preventDefault();
                                this.props.selectProject(project);
                            }}>
                            {project.name}
                        </button>
                    );
                })}
            </div>
        );
    }
}
