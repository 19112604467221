import React from 'react';
import { Text, Spinner, SearchBox, ActionButton, Dropdown, Stack, TextField, PrimaryButton, DefaultButton } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';
import Cryptr from 'cryptr';

import './Dashboard.css';

import ProjectList from '../projectlist/ProjectList';
import ProjectInfo from '../projectinfo/ProjectInfo';
import EstimatesList from '../estimateslist/EstimatesList';
import JobList from '../joblist/JobList';
import MongoDB from '../../services/mongo';
import JolenApi from '../../services/jolenapi';
import { JobStatus } from '../../models/IMongoDB';
import { DB_NAMES, ENVIRONMENT } from '../../credentials/creds';
import { IProcoreProject } from '../../models/IProcore';

initializeIcons();

const secondsPerRefresh: number = 30;

export enum DashboardView {
    "JobLogs" = "Job Logs",
    "Projects" = "Projects",
    "Estimates" = "Estimates"
}

export interface IJobCounts {
    Error: number;
    'Not Started': number;
    'In Progress': number;
    Completed: number;
}

export interface DashboardState {
    databaseTarget: DB_NAMES;
    authenticated: boolean;
    authenticating: boolean;
    initialCounts: IJobCounts;
    jobCounts: IJobCounts;
    initialized: boolean;
    loginFailed: boolean;
    loadingCounts: boolean;
    jobListToShow: JobStatus;
    searchQuery: string;
    typedPassword: string;
    generateKey: boolean;
    keyToEncrypt: string;
    passwordToSet: string;
    generatedKeyOutput: string;
    view: DashboardView;    
    projects: IProcoreProject[];
    selectedProject: IProcoreProject;
}

export interface DashboardProps {}
export const EnvironmentBadgeClassMap: any = {};
EnvironmentBadgeClassMap[DB_NAMES.DEV] = 'badge-success';
EnvironmentBadgeClassMap[DB_NAMES.TEST] = 'badge-warning';
EnvironmentBadgeClassMap[DB_NAMES.PROD] = 'badge-danger';

export const EnvironmentNameMap: any = {};
EnvironmentNameMap[DB_NAMES.DEV] = ENVIRONMENT.DEV;
EnvironmentNameMap[DB_NAMES.TEST] = ENVIRONMENT.TEST;
EnvironmentNameMap[DB_NAMES.PROD] = ENVIRONMENT.PROD;

export default class Dashboard extends React.Component<DashboardProps, DashboardState> {
    public state: DashboardState = {
        authenticated: false,
        authenticating: false,
        loginFailed: false,
        initialized: false,
        loadingCounts: false,
        typedPassword: '',
        jobListToShow: JobStatus.NotStarted,
        searchQuery: '',
        generateKey: false,
        keyToEncrypt: '',
        passwordToSet: '',
        generatedKeyOutput: '',
        databaseTarget: sessionStorage.DBNAME || DB_NAMES.DEV,
        jobCounts: {
            Error: 0,
            'Not Started': 0,
            'In Progress': 0,
            Completed: 0,
        },
        initialCounts: {
            Error: 0,
            'Not Started': 0,
            'In Progress': 0,
            Completed: 0,
        },
        view: DashboardView.JobLogs,
        projects: [],
        selectedProject: {} as IProcoreProject
    };

    private _mongoDb: MongoDB = {} as MongoDB;
    private _jolenApi: JolenApi = {} as JolenApi;

    private refreshTimer: number = 0;

    public componentDidMount(): void {
        if (sessionStorage.decryptedKey) {
            this._authenticate();
        }
    }

    private _logout(): void {
        sessionStorage.removeItem('decryptedKey');
        sessionStorage.removeItem('decryptedApiKey');
        sessionStorage.removeItem('lastUsedPassword');
        this.setState({ authenticated: false, initialized: false, typedPassword: '' });
    }

    private _generateEncryptedKey = async (): Promise<void> => {
        const cryptr = new Cryptr(this.state.passwordToSet);
        const encrypted = cryptr.encrypt(this.state.keyToEncrypt);

        this.setState({
            generatedKeyOutput: encrypted,
        });
    };

    private _authenticate = async (resetCachedPW?: boolean): Promise<void> => {
        let userInputtedKey = this.state.typedPassword;

        if(resetCachedPW) {
            sessionStorage.setItem('lastUsedPassword', userInputtedKey);
        } else {
            userInputtedKey = sessionStorage.lastUsedPassword;
        }

        if(!userInputtedKey) { return; }

        // Create the login credential
        const cryptr = new Cryptr(userInputtedKey);

        this.setState({
            loginFailed: false,
            authenticating: true,
        });

        this._mongoDb = new MongoDB(this.state.databaseTarget);
        this._jolenApi = new JolenApi(EnvironmentNameMap[this.state.databaseTarget]);

        const mongoAuthenticated = await this._mongoDb.Login(cryptr);
        const jolenApiAuthenticated = this._jolenApi.Login(cryptr);

        const loggedIn = mongoAuthenticated && jolenApiAuthenticated;        

        if (loggedIn) {
            this.setState(
                {
                    authenticated: true,
                    authenticating: false,
                },
                () => {
                    this._getJobCounts();
                    this._getProjects();
                    this.refreshTimer = window.setInterval(this._getJobCounts, secondsPerRefresh * 1000);
                }
            );
        } else {
            this.setState({
                loginFailed: true,
                authenticating: false,
            });
        }
    };

    private _getProjects = async (): Promise<void> => {
        const projects = await this._jolenApi.GetProcoreProjects();

        this.setState({
            projects
        });
    }

    private _pushAllEstimates = async (): Promise<void> => {
        this._jolenApi.SyncAllEstimatesToAllUsers();
    }

    private _getJobCounts = async (resetInitialCounts?: boolean): Promise<void> => {
        if(this.state.view !== DashboardView.JobLogs) { return; }
        this.setState({
            loadingCounts: true,
        });

        const jobErrorCount = await this._mongoDb.CountJobs(JobStatus.Error, this.state.searchQuery);
        const jobInProgressCount = await this._mongoDb.CountJobs(JobStatus.InProgress, this.state.searchQuery);
        const jobNotStartedCount = await this._mongoDb.CountJobs(JobStatus.NotStarted, this.state.searchQuery);
        const jobCompletedCount = await this._mongoDb.CountJobs(JobStatus.Completed, this.state.searchQuery);

        const jobCounts: IJobCounts = {
            Error: jobErrorCount,
            'Not Started': jobNotStartedCount,
            'In Progress': jobInProgressCount,
            Completed: jobCompletedCount,
        };

        this.setState({
            jobCounts,
            initialCounts: this.state.initialized && !resetInitialCounts ? this.state.initialCounts : { ...jobCounts },
            initialized: true,
            loadingCounts: false,
        });
    };

    private _getBadges = (type: JobStatus) => {
        const deltaCount = this.state.jobCounts[type] - this.state.initialCounts[type];

        return (
            <span style={{ float: 'right' }}>
                {deltaCount > 0 ? <span className="badge badge-success">{deltaCount}</span> : null}
                <span className="badge badge-light">{this.state.jobCounts[type]}</span>
            </span>
        );
    };

    private _changeList = async (jobListToShow: JobStatus) => {
        await this._getJobCounts();
        const newInitialJobCounts = { ...this.state.initialCounts };
        newInitialJobCounts[jobListToShow] = this.state.jobCounts[jobListToShow];

        this.setState({
            jobListToShow,
            initialCounts: newInitialJobCounts,
        });
    };

    private _getNavLink = (targetList: JobStatus): JSX.Element => {
        return (
            <button
                type="button"
                className={`nav-link ${this.state.jobListToShow === targetList ? 'active' : ''} btn btn-link text-left`}
                onClick={(ev) => {
                    ev.preventDefault();
                    this._changeList(targetList);
                }}>
                {targetList}
                {this._getBadges(targetList)}
            </button>
        );
    };

    private _search = (query: string): void => {
        this.setState(
            {
                searchQuery: query,
            },
            () => {
                this._getJobCounts(true);
            }
        );
    };

    private _changeView = (view: DashboardView): void => {
        this.setState({
            view
        });
    }

    public render(): JSX.Element {
        return (
            <div className={`container-fluid`}>
                <div className={`row ${!this.state.initialized ? `justify-content-md-center` : ``}`}>
                    <div className="col col-12 col-md-7 my-3">
                        <Text block variant="xLargePlus">
                            Procore/SPO Sync Dashboard{this.state.initialized ? ` - ${this.state.view}` : ''}
                            <ActionButton
                                iconProps={{ iconName: 'AzureKeyVault' }}
                                onClick={() => {
                                    this.setState({ generateKey: true });
                                }}
                            />
                            {this.state.authenticated ? (
                                <ActionButton iconProps={{ iconName: 'PlugDisconnected' }} onClick={() => this._logout()} />
                            ) : null}
                        </Text>
                    </div>
                    {this.state.initialized ? (
                        <div className="col my-3" style={{whiteSpace: 'nowrap'}}>
                            <DefaultButton className={`mr-3`} text={DashboardView.JobLogs} onClick={() => this._changeView(DashboardView.JobLogs)} primary={this.state.view === DashboardView.JobLogs}/>
                            <DefaultButton className={`mr-3`} text={DashboardView.Projects} onClick={() => this._changeView(DashboardView.Projects)} primary={this.state.view === DashboardView.Projects} />
                            <DefaultButton className={`mr-3`} text={DashboardView.Estimates} onClick={() => this._changeView(DashboardView.Estimates)} primary={this.state.view === DashboardView.Estimates} />
                        </div>
                    ) : null}
                    {this.state.initialized ? (
                        <div className="col my-3">
                            <div className={`badge ${EnvironmentBadgeClassMap[this.state.databaseTarget]} mb-3 d-block`} style={{lineHeight: '26px'}}>
                                <Text block variant="large" title={this.state.databaseTarget}>
                                    {EnvironmentNameMap[this.state.databaseTarget]}
                                </Text>
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.state.generateKey ? (
                    <div className="row">
                        <div className="col col-xs-12 my-3">
                            <Text block variant="large">
                                Generate Key
                            </Text>
                            <div className="form-group">
                                <label>Key to encrypt</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.keyToEncrypt}
                                    onChange={(ev) => {
                                        this.setState({ keyToEncrypt: ev.currentTarget.value });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.passwordToSet}
                                    onChange={(ev) => {
                                        this.setState({ passwordToSet: ev.currentTarget.value });
                                    }}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={this.state.keyToEncrypt === '' || this.state.passwordToSet === ''}
                                onClick={() => {
                                    this._generateEncryptedKey();
                                }}>
                                Generate
                            </button>
                            {this.state.generatedKeyOutput !== '' ? (
                                <div className="alert alert-success">
                                    Your generated key:
                                    <br />
                                    <span style={{ fontSize: '0.8em' }}>{this.state.generatedKeyOutput}</span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {this.state.authenticated ? (
                    this.state.initialized ? (
                        <div className="row">
                            <div className="col col-xs-12 col-sm-5 col-md-4 col-lg-2">
                                {this.state.view === DashboardView.JobLogs ? (
                                    <div>
                                        <div className={`mb-3`}>
                                            <SearchBox placeholder="Search logs" onSearch={(newValue) => this._search(newValue)} onClear={(newValue) => this._search(newValue)} />
                                        </div>
                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {this._getNavLink(JobStatus.NotStarted)}
                                            {this._getNavLink(JobStatus.Completed)}
                                            {this._getNavLink(JobStatus.InProgress)}
                                            {this._getNavLink(JobStatus.Error)}
                                        </div>
                                        <div>{this.state.loadingCounts ? <Spinner label="Refreshing..." /> : null}</div>
                                    </div>
                                ) : null}
                                {this.state.view === DashboardView.Projects ? (
                                    <div>
                                        <ProjectList projects={this.state.projects} currentProject={this.state.selectedProject} selectProject={(selectedProject: IProcoreProject) => this.setState({selectedProject})} />
                                    </div>
                                ) : null}
                                {this.state.view === DashboardView.Estimates ? (
                                    <div>
                                        <EstimatesList pushAllEstimatesFunc={() => this._pushAllEstimates()} />
                                    </div>
                                ) : null}
                            </div>
                            <div className="col col-xs-12 col-sm-7 col-md-8 col-lg-10">
                                {this.state.view === DashboardView.JobLogs ? (
                                    <JobList
                                        jobListType={this.state.jobListToShow}
                                        secondsPerRefresh={secondsPerRefresh}
                                        mongo={this._mongoDb}
                                        itemsPerPage={20}
                                        searchQuery={this.state.searchQuery}
                                        totalResults={this.state.jobCounts[this.state.jobListToShow]}
                                    />
                                ) : this.state.selectedProject.id ? (
                                    <ProjectInfo project={this.state.selectedProject} jolenApi={this._jolenApi} mongo={this._mongoDb} />
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <Spinner label="Initializing..." />
                    )
                ) : (
                    <div className="row justify-content-md-center">
                        <div className="col col-12 col-md-6 col-lg-5 my-3">
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack.Item>
                                    <Dropdown
                                        selectedKey={this.state.databaseTarget}
                                        options={[
                                            { key: DB_NAMES.DEV, text: `DEV` },
                                            { key: DB_NAMES.TEST, text: `TEST` },
                                            { key: DB_NAMES.PROD, text: `PROD` },
                                        ]}
                                        onChange={(ev, newValue) => {
                                            sessionStorage.DBNAME = newValue?.key;

                                            this.setState({
                                                databaseTarget: newValue?.key as DB_NAMES,
                                            });
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <TextField
                                        type="password"
                                        id="appPW"
                                        value={this.state.typedPassword}
                                        onChange={(ev, newValue) => {
                                            if(newValue) {
                                                this.setState({ typedPassword: newValue });
                                            }
                                        }}
                                        onKeyUp={(ev) => {
                                            if (ev.key === 'Enter') {
                                                this._authenticate(true);
                                            }
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton
                                        type="button"
                                        disabled={this.state.authenticating || this.state.typedPassword === ''}
                                        onClick={() => {
                                            this._authenticate(true);
                                        }}>
                                        Login
                                    </PrimaryButton>
                                </Stack.Item>
                            </Stack>
                            {this.state.loginFailed ? <div className="alert alert-danger mt-3">Authentication failed.</div> : null}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
